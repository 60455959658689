<template>
  <div class="coming-soon">
    <img src="../assets/images/coming-soon.png" class="hidden-xs" alt="" />
    <img src="../assets/images/coming-soon-m.png" class="visible-xs" alt="" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.$parent.showFooter = false;
    this.$parent.showHeader = false;
  },
};
</script>

<style lang="scss" scoped>
.coming-soon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: url("@/assets/images/background-mobile.jpg") no-repeat center top;
  background-size: 100%;
  @media (min-width: $sm) {
    background: url("@/assets/images/background.jpg") no-repeat center top;
    background-size: 100%;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(#fff, 0.2);
    backdrop-filter: blur(6px);
  }
  img {
    position: relative;
    width: 70vw;
    z-index: 10;
    @media (min-width: $sm) {
      width: 40vw;
    }
  }
}
</style>
