<template>
  <div class="winners section" id="winners">
    <div class="container">
      <div class="winners__header">
        <h2 class="winners__title section-title _big">
          <span>Победители</span>
        </h2>
      </div>
      <div class="winners__top">
        <ul class="winners__tabs">
          <li
            v-for="(tab, i) in tabs"
            :key="i"
            class="winners__tabs-nav"
            :class="{ _active: activeItem === tab.value }"
            @click="(activeItem = tab.value), getWinnersResult(tab.value)"
          >
            {{ tab.label }}
          </li>
        </ul>
        <div class="only-desktop">
          <div class="winners__search">
            <form
              class="winners__search-form"
              v-on:submit.prevent="searchForm"
              :class="{
                error:
                  validationStatus($v.searchQuery) ||
                  this.errorMessage.searchQuery,
              }"
            >
              <input
                class="winners__search-field"
                type="email"
                name="term"
                v-model="searchQuery"
                @focus="errorReset()"
                placeholder="поиск по E-mail"
              />
              <button type="submit" class="winners__search-button"></button>
              <div
                v-if="searchQuery"
                @click="formReset()"
                class="winners__search-clear"
              ></div>
              <div
                class="error-hint"
                v-if="
                  validationStatus($v.searchQuery) ||
                  this.errorMessage.searchQuery
                "
                v-html="
                  (!$v.searchQuery.required && $v.searchQuery.$error
                    ? 'Обязательное поле'
                    : !$v.searchQuery.email && $v.searchQuery.$error
                    ? 'Некорректный e-mail'
                    : '') || this.errorMessage.searchQuery
                "
              ></div>
            </form>
          </div>
        </div>
      </div>

      <div class="winners__content">
        <div class="winners__search only-mobile">
          <form
            class="winners__search-form"
            v-on:submit.prevent="searchForm"
            :class="{
              error:
                validationStatus($v.searchQuery) ||
                this.errorMessage.searchQuery,
            }"
          >
            <input
              class="winners__search-field"
              type="email"
              name="term"
              v-model="searchQuery"
              @focus="errorReset()"
              placeholder="поиск по E-mail"
            />
            <button type="submit" class="winners__search-button"></button>
            <div
              v-if="searchQuery"
              @click="formReset()"
              class="winners__search-clear"
            ></div>
            <div
              class="error-hint"
              v-if="
                validationStatus($v.searchQuery) ||
                this.errorMessage.searchQuery
              "
              v-html="
                (!$v.searchQuery.required && $v.searchQuery.$error
                  ? 'Обязательное поле'
                  : !$v.searchQuery.email && $v.searchQuery.$error
                  ? 'Некорректный e-mail'
                  : '') || this.errorMessage.searchQuery
              "
            ></div>
          </form>
        </div>
        <div class="winners__result">
          <Table :thead="fields" v-if="winners && winners.length">
            <template v-slot:tbody>
              <tr v-for="(item, index) in winners" :key="index">
                <td style="width: 30%">
                  {{ item.name }}
                </td>
                <td style="width: 30%">
                  {{ item.email }}
                </td>
                <td style="width: 40%">
                  {{ item.prize }}
                </td>
              </tr>
            </template>
            <template v-slot:mobile>
              <table class="winners-tab-mobile">
                <tr v-for="(item, index) in winners" :key="index">
                  <td>
                    <table class="table-mobile__inner">
                      <tr>
                        <td>
                          {{ item.email }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {{ item.prize }}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </template>
          </Table>
          <div class="no-results" v-if="!winners.length">
            <div class="no-results" v-if="noSearchResults">
              Среди победителей данный аккаунт не найден
            </div>
            <div v-else-if="activeItem === 3">Победитель проверяется и&nbsp;скоро будет опубликован</div>
            <div v-else>Розыгрыш еще не&nbsp;проводился</div>
          </div>
        </div>
      </div>
    </div>
    <img
      src="../assets/images/animations/table.svg"
      alt=""
      class="winners__table"
    />
    <img
      src="../assets/images/animations/leaf.svg"
      alt=""
      class="winners__leaf"
    />
    <AppBubbles left="86%" top="57%" :amount="10" class="hidden-xs" />
  </div>
</template>

<script>
import AppBubbles from "./AppBubbles.vue";
import Table from "@/components/Table.vue";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    Table,
    AppBubbles,
  },
  data: function () {
    return {
      tabs: [
        {
          label: "ежедневные призы",
          value: 1,
        },
        {
          label: "еженедельные призы",
          value: 2,
        },
        {
          label: "главный приз",
          value: 3,
        },
      ],
      page: 1,
      perPage: 1,
      pages: [],
      currentWeek: "03.10.2022-09.10.2022",
      searchQuery: null,
      date: null,
      total: 0,
      noResults: false,
      noSearchResults: false,
      errorMessage: {
        date: null,
        searchQuery: null,
      },
      fields: [
        {
          text: "имя",
          size: "30%",
        },
        {
          text: "e-mail",
          size: "30%",
        },
        {
          text: "приз",
          size: "40%",
        },
      ],
      winners: [],
      showPaginate: true,
      activeItem: 1,
    };
  },
  validations: {
    searchQuery: { required },
  },
  methods: {
    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        searchQuery: null,
      };
    },
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    setPages(total) {
      this.pages = [];
      if (total) {
        for (let index = 1; index <= total.length; index++) {
          this.pages.push(index);
        }
      }
    },
    paginate() {
      let page = this.page;
      this.$store
        .dispatch("GetWinners", { page: page, type: this.activeItem })
        .then((response) => {
          this.winners = response.data.rows;
        });
    },
    getWinnersResult(val) {
      this.noSearchResults = false;
      this.$store
        .dispatch("GetWinners", { type: val, page: this.page })
        .then((response) => {
          this.winners = response.data.rows;
          if (this.winners.length < 1) {
            this.noResults = true;
          } else {
            this.noResults = false;
          }
        });
    },
    searchForm() {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.page = 1;
      this.$store
        .dispatch("SearchWinners", {
          email: this.searchQuery,
          type: this.activeItem,
          page: this.page,
        })
        .then((response) => {
          this.winners = response.data;
          if (!this.winners.length) {
            this.noSearchResults = true;
          } else {
            this.noSearchResults = false;
          }
        });
    },
    formReset() {
      this.$v.$reset();
      this.searchQuery = null;
      this.noSearchResults = false;
      this.$store
        .dispatch("GetWinners", {
          type: this.activeItem,
        })
        .then((response) => {
          this.winners = response.data.rows;
        });
    },
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
  },
  mounted() {},
  created() {
    try {
      this.$store
        .dispatch("GetWinners", {
          type: this.activeItem,
        })
        .then((response) => {
          this.total = response.data.paging.total;
          this.winners = response.data.rows;
          if (!this.winners.length) {
            this.noResults = true;
          } else {
            this.noResults = false;
          }
        });
    } catch (e) {
      console.log(e);
      this.noResults = true;
    }
  },
  watch: {
    winners() {
      this.setPages(this.winners);
    },
  },
  computed: {
    // displayedWinners() {
    //   return this.paginate(this.winners);
    // },
  },
};
</script>

<style lang="scss">
.winners {
  position: relative;
  display: flex;
  padding-top: rem(70px);
  padding-bottom: rem(265px);
  align-items: center;
  z-index: 1;
  background-size: cover;
  &__title {
    margin-bottom: rem(31px);
    color: $red;
  }
  &__top {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 10;
  }
  &__tabs {
    display: flex;
    flex-direction: column-reverse;
    &-nav {
      position: relative;
      height: rem(54px);
      padding: 0 rem(25px);
      margin-bottom: rem(11px);
      background: #ffb59d;
      color: rgba($red, 0.5);
      text-transform: uppercase;
      line-height: rem(54px);
      text-align: center;
      font-family: $medium-font;
      font-size: rem(14px);
      border-radius: rem(25px);
      cursor: pointer;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        display: block;
        width: 100%;
        height: 100%;
        transform: translateX(-50%);
        background: $pink;
        border-radius: 25px;
        opacity: 0;
        z-index: -1;
      }
      &._active {
        background: $pink;
        color: $red;
        &::before {
          opacity: 1;
        }
      }
    }
  }
  &__select {
    margin: 0;
    width: 100%;
  }
  &__header {
    display: flex;
    justify-content: center;
  }
  .container {
    position: relative;
  }
  &__search-field {
    width: 100%;
    padding: 2px rem(40px) 0 rem(40px);
    height: rem(50px);
    font-size: rem(14px);
    background: none;
    border: rem(2px) solid #fff2e5;
    font-family: $medium-font;
    text-transform: uppercase;
    box-shadow: none;
    text-align: center;
    color: #fff2e5;
    border-radius: rem(80px);
    outline: none;
    &::placeholder {
      color: #fff2e5;
    }
  }
  &__search {
    width: 100%;
  }
  &__search-form {
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: rem(0px) auto rem(24px);
  }
  &__search-button {
    position: absolute;
    top: 50%;
    right: rem(0px);
    transform: translateY(-50%);
    width: rem(43px);
    height: rem(43px);
    z-index: 1;
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../assets/images/icons/search-2.svg") no-repeat center;
      background-size: rem(20px);
    }
  }
  &__search-clear {
    position: absolute;
    top: 0;
    left: rem(15px);
    width: rem(18px);
    height: 100%;
    background: url("../assets/images/icons/close.svg") no-repeat center;
    background-size: 100%;
    cursor: pointer;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: rem(20px) rem(17px) rem(30px);
    background: $pink;
    border-radius: rem(25px);
  }
  &__result {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: auto;
    height: 100%;
    font-family: $bold;
    font-size: rem(18px);
  }
  &__img {
    position: absolute;
    left: 0;
    bottom: rem(-120px);
    pointer-events: none;
    z-index: 10;
  }
  .no-results {
    align-self: center;
    padding: rem(90px) 0;
    text-align: center;
    color: $red;
  }
  &__table {
    position: absolute;
    top: rem(770px);
    left: 0;
    height: rem(370px);
  }
  &__leaf {
    position: absolute;
    top: rem(700px);
    left: rem(-150px);
    height: rem(230px);
    transform-origin: top left;
    animation: leaf 5s ease-in-out infinite forwards;
  }
  @media (min-width: $sm) {
    padding-bottom: 0;
    padding-top: rem(148px);
    .container {
      width: rem(1130px);
      padding-bottom: 0 !important;
    }
    &__table {
      top: rem(750px);
      left: 0;
      height: rem(588px);
    }
    &__leaf {
      top: rem(610px);
      left: rem(-150px);
      height: rem(330px);
    }
    &__content {
      position: relative;
      flex-direction: row;
      padding: rem(50px) rem(50px) rem(100px) rem(60px);
    }
    &__top {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: rem(11px);
      padding-top: 0;
    }
    &__title {
      color: #fff;
      margin-bottom: rem(58px) !important;
    }
    &__search-field {
      width: 100%;
      height: rem(54px);
      padding: 2px rem(25px) 0;
      font-size: rem(14px);
      color: $red;
      border: rem(2px) solid $red;
      &::placeholder {
        color: $red;
      }
    }
    &__result {
      min-height: rem(500px);
    }
    &__tabs {
      flex: auto;
      flex-direction: row;
      &-nav {
        width: rem(232px);
        flex-grow: 1;
        height: rem(54px);
        padding: 0 rem(20px);
        line-height: rem(56px);
        margin-bottom: 0;
        margin-right: rem(12px);
        font-size: rem(14px);
        border-radius: rem(8px);
        &::before {
          top: rem(-2px);
          left: rem(0px);
          width: calc(100% + #{rem(4px)});
          height: rem(90px);
          transform: translateX(0%);
          border-radius: 8px;
          border-bottom-right-radius: rem(0px);
          border-bottom-left-radius: rem(0px);
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
    &__select {
      flex: 0 0 40%;
    }
    &__search {
      width: auto;
      min-width: rem(372px);
      margin-left: rem(12px);
      &-form {
        margin: 0;
      }
    }
    &__search-button {
      right: rem(17px);
      &::after {
        background: url("../assets/images/icons/search.svg") no-repeat center;
        background-size: rem(20px);
      }
    }
    &-tab-desktop {
      display: block;
    }
    &-tab-mobile {
      display: none;
    }
    .no-results {
      max-width: 70%;
      margin: auto;
      font-size: rem(22px);
    }
  }
}
@keyframes leaf {
  0%,
  100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(5deg);
  }
}
</style>
