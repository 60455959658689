<template>
  <label
    class="form__field"
    :class="
      color === 'white'
        ? 'form__field--white'
        : '' || color === 'green'
        ? 'form__field--green'
        : ''
    "
  >
    <div class="form__caption" v-if="label">{{ label }}</div>
    <input
      v-if="maskValue != ''"
      :disabled="isDisabled"
      :type="type"
      :value="value"
      :id="fieldId"
      :maxlength="maxlength"
      :placeholder="placeholder"
      class="form__field-input"
      :class="size ? 'form__field-input--' + size : ''"
      v-model.trim="retValue"
      v-mask="maskValue"
      :name="name"
      @focus="errorReset()"
      @input="onInput()"
      @blur="onBlur()"
    />
    <input
      v-else
      :disabled="isDisabled"
      :type="type"
      :id="fieldId"
      :value="value"
      :maxlength="maxlength"
      :placeholder="placeholder"
      class="form__field-input"
      :class="size ? 'form__field-input--' + size : ''"
      v-model.trim="retValue"
      :name="name"
      @focus="errorReset()"
      @input="onInput()"
      @blur="onBlur()"
    />
    <div class="error-hint" v-if="error">{{ error }}</div>
  </label>
</template>
<script>
export default {
  data() {
    return {
      retValue: this.field,
      maskValue: this.mask || "",
      nameValue: this.name || "",
    };
  },

  methods: {
    errorReset() {
      this.$parent.errorReset();
    },

    onInput() {
      this.$emit("input", this.retValue);
    },

    onBlur() {
      this.$emit("input", this.retValue);
    },
  },
  props: {
    field: String,
    fieldId: String,
    mask: String,
    name: String,
    maxlength: {
      type: [String, Number],
    },
    label: {
      type: String,
    },
    error: {
      type: [String, Boolean],
    },
    value: [String, Number],
    placeholder: {
      type: String,
    },
    type: {
      type: String,
      default: () => {
        return "text";
      },
    },
    isDisabled: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    size: {
      type: String,
      default: () => {
        return "sm";
      },
    },
    color: {
      type: String,
      default: () => {
        return "white";
      },
    },
  },

  components: {},
};
</script>
<style lang="scss">
.form__field--white {
  width: 100%;
  margin-bottom: rem(12px);
  @media (min-width: $md) {
    margin-bottom: rem(12px);
  }
}

.form__field-input,
.dadata-input.form__field-input {
  width: 100%;
  height: rem(32px);
  padding: rem(3px) rem(13px) 0;
  max-width: 100%;
  appearance: none;
  font-size: rem(14px);
  background: #ffffff;
  border-radius: rem(30px);
  letter-spacing: rem(1px);
  transition: all 0.3s ease-in-out;
  color: $red;
  font-family: $bold;
  ._centered-text & {
    text-align: center;
  }
  ._bordered & {
    height: rem(56px);
    text-transform: uppercase;
    background: #fff2e5;
    border: 1px solid $red;
  }
  &::placeholder {
    color: #9c9b9b;
    ._bordered & {
      color: $red;
      font-family: $reg;
      opacity: 1;
    }
  }
  &:focus {
    outline: none;
    & ~ .form__caption {
      opacity: 0;
    }
  }
  &--lg {
    height: rem(70px);
    padding: 0 rem(20px);
    font-weight: 400;
    font-size: rem(14px);
    &::placeholder {
      font-weight: 400;
      font-size: rem(14px);
    }
  }
  @media (min-width: $md) {
    height: rem(32px);
    border-color: $red;
  }
}
</style>
