var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"authorization__signin"},[_c('div',{staticClass:"modal__subtitle"},[_vm._v("для участия в акции")]),_c('div',{staticClass:"authorization__signin-fields"},[_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.email) || _vm.errorMessage.email,
      },attrs:{"label":"E-mail","placeholder":"Ваш E-mail","type":"email","field":_vm.email,"error":(!_vm.$v.email.required && _vm.$v.email.$error
          ? 'Обязательное поле'
          : !_vm.$v.email.email && _vm.$v.email.$error
          ? 'Некорректный e-mail'
          : '') || _vm.errorMessage.email},on:{"input":function($event){_vm.email = $event}}}),_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.password) || this.errorMessage.password,
      },attrs:{"label":"Пароль","placeholder":"Ваш пароль","type":"password","field":_vm.password,"error":(!_vm.$v.password.required && _vm.$v.password.$error
          ? 'Обязательное поле'
          : !_vm.$v.password.minLength && _vm.$v.password.$error
          ? 'Минимум ' + _vm.$v.password.$params.minLength.min + ' символов'
          : '') || this.errorMessage.password},on:{"input":function($event){_vm.password = $event}}}),_c('div',{staticClass:"authorization__row"},[_c('a',{staticClass:"forget-link",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){$event.preventDefault();return _vm.switchToRecover()}}},[_vm._v("Забыли пароль? "),_c('b',[_vm._v("Восстановить пароль")])])]),_c('div',{staticClass:"authorization__signin-footer"},[_c('button',{staticClass:"btn btn--secondary btn--icon",class:{ loading: _vm.submitStatus == 'PENDING' },attrs:{"type":"button"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Войти "),_c('svg',{attrs:{"width":"27","height":"16","viewBox":"0 0 27 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1.28336 9.22139H22.8134L18.7798 13.255C17.6212 14.4136 19.3591 16.1515 20.5177 14.9929L24.1913 11.3136L26.6435 8.85751C27.1188 8.37941 27.1188 7.60725 26.6435 7.12915L20.5177 0.996193C20.2847 0.756702 19.9639 0.622324 19.6296 0.625103C18.5263 0.625242 17.9849 1.96909 18.7798 2.73417L22.823 6.76781H1.21992C-0.479293 6.85212 -0.35242 9.30597 1.28336 9.22139Z","fill":"#98090C"}})]),_c('img',{staticClass:"loader",attrs:{"src":require("../../assets/img/spinner.svg"),"alt":""}})]),_c('button',{staticClass:"btn btn--bordered _white modal__another-option",attrs:{"type":"button"},on:{"click":function($event){return _vm.showSignUpModal()}}},[_vm._v(" Зарегистрироваться ")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }