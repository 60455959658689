var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feedback"},[_c('form',{key:_vm.indexForm,staticClass:"feedback__form"},[_c('inputText',{staticClass:"_centered-text _bordered",class:{
        error: _vm.validationStatus(_vm.$v.email) || _vm.errorMessage.email,
      },attrs:{"placeholder":"E-mail","field":_vm.email,"error":(!_vm.$v.email.required && _vm.$v.email.$error
          ? 'Обязательное поле'
          : !_vm.$v.email.email && _vm.$v.email.$error
          ? 'Некорректный e-mail'
          : '') || _vm.errorMessage.email},on:{"input":function($event){_vm.email = $event}}}),_c('inputText',{staticClass:"_centered-text _bordered",class:{ error: _vm.validationStatus(_vm.$v.name) || _vm.errorMessage.name },attrs:{"placeholder":"Имя","field":_vm.name,"error":(!_vm.$v.name.required && _vm.$v.name.$error
          ? 'Обязательное поле'
          : !_vm.$v.name.rus && _vm.$v.name.$error
          ? 'Введите текст на русском языке'
          : '') || _vm.errorMessage.name},on:{"input":function($event){_vm.name = $event}}}),_c('Select',{class:{
        error: _vm.validationStatus(_vm.$v.subject) || _vm.errorMessage.subject,
      },attrs:{"color":"grey","className":"_bordered","options":[
        { id: 1, label: 'Регистрация в акции' },
        { id: 2, label: 'Призовой фонд' },
        { id: 3, label: 'Вопросы по правилам' },
        { id: 4, label: 'Технические вопросы' },
        { id: 5, label: 'Другое' },
      ],"field":"Тема","error":(!_vm.$v.subject.required && _vm.$v.subject.$error
          ? 'Обязательное поле'
          : '') || _vm.errorMessage.subject},on:{"input":function($event){_vm.subject = $event.id}}}),_c('textArea',{key:_vm.indexForm2,class:{
        error: _vm.validationStatus(_vm.$v.message) || _vm.errorMessage.message,
      },attrs:{"placeholder":"Текст обращения","field":_vm.message,"error":(!_vm.$v.message.required && _vm.$v.message.$error
          ? 'Обязательное поле'
          : '') || _vm.errorMessage.message},on:{"input":function($event){_vm.message = $event}}}),_vm._v(" "),_c('div',{staticClass:"feedback__checkbox_list"},[_c('checkBox',{class:{
          error: _vm.validationStatus(_vm.$v.checkbox) || this.errorMessage.checkbox,
        },attrs:{"label":`Я даю согласие на&nbsp;обработку <br><a href='${_vm.publicPath}docs/terms-of-use.pdf' target='_blank' class='_undeline-link _bold'>персональных данных</a>`,"field":_vm.checkbox,"error":this.errorMessage.checkbox},on:{"input":function($event){_vm.checkbox = $event}}})],1),_c('div',{staticClass:"feedback__footer"},[_c('button',{staticClass:"btn btn--bordered w-100",class:{ loading: _vm.submitStatus == 'PENDING' },attrs:{"type":"button"},on:{"click":function($event){_vm.submit(), _vm.gtmEvent('ask_question', 'click', 'internal')}}},[_c('span',[_vm._v("задать вопрос")]),_c('div',{staticClass:"icon-wrapper"},[_c('svg',{staticClass:"icon",attrs:{"width":"30","height":"16","viewBox":"0 0 30 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1.93677 7C1.38448 7 0.936768 7.44772 0.936768 8C0.936768 8.55228 1.38448 9 1.93677 9L1.93677 7ZM28.8006 8.70711C29.1911 8.31658 29.1911 7.68342 28.8006 7.29289L22.4366 0.928932C22.0461 0.538408 21.4129 0.538408 21.0224 0.928932C20.6319 1.31946 20.6319 1.95262 21.0224 2.34315L26.6792 8L21.0224 13.6569C20.6319 14.0474 20.6319 14.6805 21.0224 15.0711C21.4129 15.4616 22.0461 15.4616 22.4366 15.0711L28.8006 8.70711ZM1.93677 9L28.0935 9V7L1.93677 7L1.93677 9Z","fill":"#98090C"}})]),_c('svg',{staticClass:"icon",attrs:{"width":"30","height":"16","viewBox":"0 0 30 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1.93677 7C1.38448 7 0.936768 7.44772 0.936768 8C0.936768 8.55228 1.38448 9 1.93677 9L1.93677 7ZM28.8006 8.70711C29.1911 8.31658 29.1911 7.68342 28.8006 7.29289L22.4366 0.928932C22.0461 0.538408 21.4129 0.538408 21.0224 0.928932C20.6319 1.31946 20.6319 1.95262 21.0224 2.34315L26.6792 8L21.0224 13.6569C20.6319 14.0474 20.6319 14.6805 21.0224 15.0711C21.4129 15.4616 22.0461 15.4616 22.4366 15.0711L28.8006 8.70711ZM1.93677 9L28.0935 9V7L1.93677 7L1.93677 9Z","fill":"#F8E9D9"}})])])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }