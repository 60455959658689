<template>
  <div class="hero section" id="home">
    <div class="container">
      <img src="../assets/images/monetka.svg" alt="" class="hero__monetka" />
      <div class="hero__subtitle">с 1 по 31 мая 2023 года</div>
      <div class="hero__title">на море</div>
      <div class="hero__suptitle">с «Принцессой Нури»</div>
      <button
        class="btn btn--primary"
        @click="
          toCheckUpload(),
            gtmEvent('register_receipt_1_screen', 'click', 'internal')
        "
      >
        <span>зарегистрировать чек</span>
        <div class="icon-wrapper">
          <svg
            class="icon"
            width="30"
            height="16"
            viewBox="0 0 30 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.93677 7C1.38448 7 0.936768 7.44772 0.936768 8C0.936768 8.55228 1.38448 9 1.93677 9L1.93677 7ZM28.8006 8.70711C29.1911 8.31658 29.1911 7.68342 28.8006 7.29289L22.4366 0.928932C22.0461 0.538408 21.4129 0.538408 21.0224 0.928932C20.6319 1.31946 20.6319 1.95262 21.0224 2.34315L26.6792 8L21.0224 13.6569C20.6319 14.0474 20.6319 14.6805 21.0224 15.0711C21.4129 15.4616 22.0461 15.4616 22.4366 15.0711L28.8006 8.70711ZM1.93677 9L28.0935 9V7L1.93677 7L1.93677 9Z"
              fill="#F8E9D9"
            />
          </svg>
          <svg
            class="icon"
            width="30"
            height="16"
            viewBox="0 0 30 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.93677 7C1.38448 7 0.936768 7.44772 0.936768 8C0.936768 8.55228 1.38448 9 1.93677 9L1.93677 7ZM28.8006 8.70711C29.1911 8.31658 29.1911 7.68342 28.8006 7.29289L22.4366 0.928932C22.0461 0.538408 21.4129 0.538408 21.0224 0.928932C20.6319 1.31946 20.6319 1.95262 21.0224 2.34315L26.6792 8L21.0224 13.6569C20.6319 14.0474 20.6319 14.6805 21.0224 15.0711C21.4129 15.4616 22.0461 15.4616 22.4366 15.0711L28.8006 8.70711ZM1.93677 9L28.0935 9V7L1.93677 7L1.93677 9Z"
              fill="#98090C"
            />
          </svg>
        </div>
      </button>
      <a
        :href="`${publicPath}docs/rules.pdf`"
        @click="gtmEvent('rules', 'click', 'send'), gtmEvent('rules', 'click', 'internal');"
        class="hero__rules _undeline-link hidden-xs"
        target="_blank"
        >Полные правила акции</a
      >
    </div>
    <img src="../assets/images/animations/palm.svg" alt="" class="hero__palm" />
    <div class="hero__ships">
      <img
        v-for="i in 3"
        :key="i"
        :src="require(`../assets/images/animations/ship-${i}.svg`)"
        class="hero__ship hidden-xs"
        alt=""
      />
    </div>
    <div class="hero__clouds">
      <img
        v-for="i in 3"
        :key="i"
        :src="require(`../assets/images/animations/cloud-${i}.svg`)"
        class="hero__cloud"
        alt=""
      />
    </div>
    <div class="hero__parallax-wrap js-parallax-wrap">
      <img
        value="25"
        src="../assets/images/animations/bird-1.svg"
        class="hero__bird hidden-xs"
        alt=""
      />
      <img
        value="25"
        src="../assets/images/animations/bird-2.svg"
        class="hero__bird hidden-xs"
        alt=""
      />
      <img
        value="15"
        src="../assets/images/animations/bird-3.svg"
        class="hero__bird hidden-xs"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    // Feedback,
  },
  data: () => ({
    publicPath: "https://www.noori-tea-promo.ru/",
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    showModal(name) {
      this.$modal.show(name);
    },
    toCheckUpload() {
      // const t = this;
      // if (this.$store.getters.user) {
      //   let apmButton = document.querySelector(
      //     "#apm-scan-qr .apm-action-button"
      //   );
      //   if (apmButton) apmButton.click();
      //   setTimeout(() => {
      //     const closeBtn = document.getElementById("apm-close-widget-button");

      //     console.log(closeBtn);
      //     closeBtn.addEventListener("click", function () {
      //       t.gtmEvent("left_receipt_registration", "do", "internal");
      //     });
      //   }, 1000);
      // } else {
      //   this.$modal.show("authorization");
      // }
      this.$modal.show("temporarily_unavailable");
    },
  },
  mounted() {
    // this.toCheckUpload()
    document.querySelector(".hero").addEventListener("mousemove", parallax);
    function parallax(event) {
      this.querySelectorAll(".js-parallax-wrap img").forEach((shift) => {
        const position = shift.getAttribute("value");
        const x = (window.innerWidth - event.pageX * position) / 200;

        shift.style.transform = `translateX(${x}px)`;
      });
    }
  },
};
</script>
<style lang="scss">
.hero {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 180vw;
  padding-top: rem(115px);
  flex-direction: column;
  z-index: 3 !important;
  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    z-index: 3;
  }
  &__monetka {
    display: none;
  }
  &__subtitle {
    margin-bottom: rem(5px);
    font-size: rem(14px);
    text-transform: uppercase;
  }
  &__title {
    font-size: rem(64px);
    font-family: $medium-font;
    text-transform: uppercase;
    line-height: 1;
  }
  &__suptitle {
    margin-bottom: rem(23px);
    font-size: rem(24px);
    line-height: 1;
  }
  &__rules {
    position: relative;
    margin-top: rem(12px);
    font-size: rem(14px);
    letter-spacing: rem(1.2px);
    line-height: 1;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      @include size(100%, 250%);
      display: block;
      transform: translate(-50%, -50%);
    }
  }
  &__palm {
    position: absolute;
    bottom: rem(20px);
    height: rem(380px);
    right: rem(-40px);
    transform-origin: bottom right;
    animation: palm 5s ease-in-out infinite forwards;
    pointer-events: none;
    z-index: 2;
  }
  &__cloud {
    position: absolute;
    z-index: 0;
    &:nth-of-type(1) {
      top: rem(115px);
      left: -6%;
      height: rem(300px);
      animation: cloud 15s ease-in-out infinite;
    }
    &:nth-of-type(2) {
      top: rem(250px);
      left: 3%;
      height: rem(200px);
      animation: cloud 15s 5s ease-in-out infinite;
    }
    &:nth-of-type(3) {
      top: rem(62px);
      left: 60%;
      height: rem(230px);
      animation: cloud2 15s ease-in-out infinite;
    }
  }
  @media (min-width: $sm) {
    position: relative;
    flex-direction: column;
    min-height: auto;
    padding-top: rem(123px);
    padding-bottom: rem(230px);
    .container {
      width: 91%;
    }
    &__palm {
      bottom: rem(-20px);
      height: rem(777px);
      right: 0;
    }
    &__cloud {
      position: absolute;
      z-index: 0;
      &:nth-of-type(1) {
        top: rem(115px);
        left: -6%;
        height: rem(300px);
        animation: cloud 30s ease-in-out infinite;
      }
      &:nth-of-type(2) {
        top: rem(250px);
        left: 3%;
        height: rem(400px);
        animation: cloud 25s 5s ease-in-out infinite;
      }
      &:nth-of-type(3) {
        top: rem(62px);
        left: 60%;
        height: rem(230px);
        animation: cloud2 25s ease-in-out infinite;
      }
    }
    &__ship {
      position: absolute;
      animation: ship 5s ease-in-out infinite forwards;
      z-index: 1;
      &:nth-of-type(1) {
        top: rem(445px);
        left: 60%;
        width: rem(42px);
      }
      &:nth-of-type(2) {
        top: rem(465px);
        left: 57%;
        width: rem(20px);
        animation-delay: 0.5s;
      }
      &:nth-of-type(3) {
        top: rem(465px);
        left: 55%;
        width: rem(15px);
        animation: ship 5s ease-in-out infinite backwards;
      }
    }
    &_parallax-wrap {
      position: absolute;
      width: 100%;
      height: 100vh;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__bird {
      position: absolute;
      &:nth-of-type(1) {
        top: rem(238px);
        left: 15%;
        width: rem(80px);
        z-index: 3;
      }
      &:nth-child(2) {
        top: rem(222px);
        left: 79%;
        width: rem(58px);
        z-index: 1;
      }
      &:nth-child(3) {
        top: rem(350px);
        left: 73%;
        width: rem(63px);
        z-index: 1;
      }
    }
    &__monetka {
      position: absolute;
      display: block;
      top: rem(32px);
      left: rem(17px);
      height: rem(83px);
    }
    &__subtitle {
      margin-bottom: rem(10px);
      font-size: rem(20px);
      font-weight: 600;
      letter-spacing: rem(2px);
      text-transform: uppercase;
    }
    &__title {
      font-size: rem(160px);
      letter-spacing: rem(2px);
    }
    &__suptitle {
      margin-top: rem(-10px);
      margin-bottom: rem(155px);
      font-size: rem(40px);
      font-weight: 400;
    }
    &__rules {
      margin-top: rem(22px);
      color: $light;
    }
  }
  @media (min-width: $md) and (max-height: 750px) {
  }

  @media (max-width: $xlg) {
  }
}

@keyframes palm {
  0%,
  100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(2deg);
  }
}
@keyframes ship {
  0%,
  100% {
    transform: rotate(-2deg) translateY(2%);
  }
  50% {
    transform: rotate(2deg) translateY(-2%);
  }
}
@keyframes cloud {
  0% {
    transform: translateX(-100%);
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: translateX(50%);
    opacity: 0;
  }
}
@keyframes cloud2 {
  0% {
    transform: translateX(100%);
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: translateX(-50%);
    opacity: 0;
  }
}
</style>
