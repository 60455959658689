<template>
  <div class="table_holder w-100">
    <div class="table--desktop table">
      <div class="table__header">
        <div class="table__header-row">
          <div
            class="table__header-col"
            v-for="(item, index) in thead"
            :key="index"
            :style="{ width: item.size }"
          >
            {{ item.text }}
          </div>
        </div>
      </div>
      <perfect-scrollbar class="table__inner" :options="settings">
        <table>
          <tbody>
            <slot name="tbody"></slot>
          </tbody>
        </table>
      </perfect-scrollbar>
    </div>
    <div class="table--mobile table">
      <div class="table__inner">
        <slot name="mobile"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["thead"],
  data: () => ({
    settings: {
      maxScrollbarLength: 66,
      wheelSpeed: 0.5,
    },
  }),
};
</script>

<style lang="scss">
.table_holder {
  td.status-bad {
    color: #912982;
  }
  td.status-moderation {
    color: #fff;
  }
  .error_icon {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    width: rem(17px);
    height: rem(17px);
    font-size: rem(12px);
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(1, 63, 93, 0.15);
    border-radius: 50%;
  }
}
.table {
  width: 100%;
  margin: 0 auto;
  font-size: rem(18px);
  &__header {
    width: 95%;
    margin-bottom: rem(40px);
    margin-right: rem(33px);
    &-row {
      display: flex;
      border-bottom: rem(2px) solid $red;
    }
    &-col {
      position: relative;
      padding: 0 rem(25px) rem(20px);
      color: $red;
      flex-grow: 0;
      flex-shrink: 0;
      text-align: left;
      font-family: $medium-font;
      text-transform: uppercase;
      &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: rem(28px);
        left: 0;
        width: 100%;
        height: rem(2px);
        background: $red;
        pointer-events: none;
        z-index: -1;
      }
    }
  }
  &--desktop {
    display: none !important;
    tr {
      margin-bottom: rem(10px);
      vertical-align: top;
    }
    td {
      position: relative;
      padding: rem(20px) rem(25px) rem(12px);
      color: $red;
      overflow: hidden;
      img {
        width: rem(30px);
        margin-right: rem(10px);
        cursor: pointer;
      }
      small {
        display: block;
        font-size: rem(12px);
        text-transform: none;
      }
    }

    tbody {
      tr {
        path {
          fill: #a5a5a5;
        }
        &._not-accepted {
          color: #f90025;
          path {
            fill: #f90025;
          }
        }
        &._accepted {
          color: #000;
          path {
            fill: #000;
          }
        }
        &._received {
          color: #209524;
          path {
            fill: #209524;
          }
        }
      }
    }
  }
  &__inner {
    width: 100%;
    max-height: rem(375px);
    min-height: rem(250px);
    overflow-y: scroll;
    // &::-webkit-scrollbar {
    //   width: rem(3px);
    // }
    // /* Track */
    // &::-webkit-scrollbar-track {
    //   background: rgba(255, 227, 202, 0.5);
    //   border-radius: 25px;
    // }

    // /* Handle */
    // &::-webkit-scrollbar-thumb {
    //   background: $red;
    //   border-radius: 10px;
    //   transition: all 0.3s ease-in-out;
    // }
  }
  table {
    position: relative;
    width: 98%;
    text-transform: uppercase;
    text-align: left;
    z-index: 1;
  }

  &--mobile {
    display: block;
    width: 100%;
    font-size: rem(16px);
    font-weight: 700;
    .table__inner {
      &::-webkit-scrollbar {
        width: rem(3px);
        opacity: 1;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: rgba(255, 227, 202, 0.5);
        border-radius: 25px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $red;
        border-radius: 10px;
        transition: all 0.3s ease-in-out;
      }
    }
    & > tr {
    }
    .table-mobile__inner {
      width: 100%;
      margin-bottom: rem(8px);
      background: $light;
      border-radius: rem(30px);
      tr {
        vertical-align: middle;
        &:first-of-type {
          td {
            padding-top: rem(8px);
            font-size: rem(13px);
          }
        }
        &:last-of-type {
          td {
            font-size: rem(11px);
            padding-bottom: rem(8px);
          }
        }
      }
      td {
        line-height: 1.5;
        text-align: center;
        text-transform: uppercase;
        color: $red;
        small {
          display: block;
          text-transform: none;
          font-size: rem(11px);
        }
        img {
          width: rem(30px);
          margin: 0 0 rem(10px);
        }
      }
    }
  }
  ._profile {
    width: 95%;
    .table-mobile__inner {
      background: none;
      border-bottom: 2px solid $red;
    }
    tr {
      vertical-align: top;
    }
    td {
      padding: rem(3px) 0px !important;
      font-size: rem(16px) !important;
      text-align: left;
      font-family: $reg;
      &:first-of-type {
        width: 45%;
        font-family: $bold;
      }
    }
    .btn {
      margin-top: rem(5px);
      margin-bottom: rem(16px);
    }
    td:last-child {
      border-right: 0;
    }
  }
  @media (min-width: $sm) {
    &--desktop {
      display: block !important;
    }
    &--mobile {
      display: none !important;
    }
    &__inner {
      max-height: rem(470px);
      padding-right: rem(33px);
      &::-webkit-scrollbar {
        width: rem(66px);
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent url("../assets/images/scrollbar-track.svg")
          no-repeat center;
        background-size: auto 100%;
        border-radius: 25px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: transparent url("../assets/images/shell.svg") no-repeat
          center;
        background-size: contain;
        border-radius: 0px;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}
</style>
