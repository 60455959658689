<template>
  <footer class="footer">
    <div class="footer__content">
      <div class="container">
        <ul class="footer__docs">
          <li v-for="(item, idx) in docs" :key="idx" class="footer__docs-item">
            <a
              :href="'https://www.noori-tea-promo.ru/docs/' + item.path"
              class="footer__docs-link"
              target="_blank"
              @click="item.gtm ? (gtmEvent('rules', 'click', 'send'),  gtmEvent('rules', 'click', 'internal')) : ''"
            >
              {{ item.name }}
            </a>
          </li>
          <li
            class="footer__docs-item"
            data-menuanchor="faq"
            @click="scrollTo()"
          >
            <router-link to="/#faq">обратная связь</router-link>
          </li>
        </ul>
        <div class="footer__text">
          <p>
            Для участия в&nbsp;акции необходимо: в&nbsp;период
            с&nbsp;01&nbsp;мая по&nbsp;31&nbsp;мая 2023&nbsp;г. приобрести
            в&nbsp;магазинах торговой сети &laquo;Монетка&raquo; любую упаковку
            чая &laquo;Принцесса Нури&raquo; и&nbsp;зарегистрировать чек
            с&nbsp;покупкой на&nbsp;сайте
            <a
              href="https://www.noori-tea-promo.ru"
              class="_undeline-link"
              target="_blank"
              >www.noori-tea-promo.ru</a
            >. Общий срок проведения акции (включая срок выдачи призов):
            с&nbsp;01&nbsp;мая по&nbsp;29&nbsp;июня 2023&nbsp;г. Количество
            призов ограничено. Призы могут отличаться от&nbsp;изображений
            на&nbsp;рекламных материалах. Информация об&nbsp;организаторе акции,
            сроках и&nbsp;правилах ее&nbsp;проведения, количестве призов,
            сроках, месте и&nbsp;порядке их&nbsp;вручения размещена
            на&nbsp;сайте
            <a
              href="https://www.noori-tea-promo.ru"
              class="_undeline-link"
              target="_blank"
              >www.noori-tea-promo.ru</a
            >.
          </p>
        </div>
        <!-- <div class="footer__copyright">
          © <span>{{ year }}</span> Все права защищены.
        </div> -->
      </div>
    </div>
    <AppBubbles left="6%" top="15%" :amount="10" />
    <AppBubbles left="90%" top="-5%" :amount="5" />
    <AppBubbles left="85%" top="55%" :amount="7" />
  </footer>
</template>
<script>
import AppBubbles from "./AppBubbles.vue";
export default {
  components: { AppBubbles },
  props: ["partners"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      docs: [
        {
          name: "правила акции",
          path: "rules.pdf",
          gtm: true
        },
        {
          name: "политика конфиденциальности",
          path: "terms-of-use.pdf",
        },
      ],
    };
  },
  methods: {
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    scrollTo() {
      const faq = document.getElementById("faq").offsetTop;
      window.scroll({
        top: faq,
        left: 0,
        behavior: "smooth",
      });
    },
  },
  computed: {
    year() {
      let date = new Date();
      return date.getFullYear();
    },
  },
};
</script>
<style scoped lang="scss">
.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: rem(70px) rem(7px) rem(40px);
  border-bottom: none;
  background: url("../assets/images/footer.svg") no-repeat center top;
  background-size: 100%;
  color: $red;
  overflow: hidden;
  z-index: 10;
  &__row,
  &__docs {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__docs {
    &-item {
      margin-bottom: rem(18px);
      font-size: rem(14px);
      line-height: rem(36px);
      text-transform: uppercase;
      font-weight: 600;
    }
  }
  &__text,
  &__copyright {
    font-size: rem(14px);
    line-height: 1.5;
    font-weight: 400;
    p {
      margin-bottom: rem(5px);
      text-align: justify;
      // overflow-wrap: break-word;
      // word-wrap: break-word;
      // -webkit-hyphens: auto;
      // -ms-hyphens: auto;
      // -moz-hyphens: auto;
      // hyphens: auto;
    }
    a {
      white-space: nowrap;
    }
  }
  &__col {
    width: 100%;
  }
  &__copyright {
    margin-top: rem(12px);
  }
  @media (max-width: $sm) {
    .container {
      padding-left: rem(13px);
      padding-right: rem(17px);
    }
  }
  @media (min-width: $sm) {
    padding: rem(190px) 0 rem(45px);
    font-size: rem(13px);
    background: url("../assets/images/footer-d.png") no-repeat center top;
    background-size: 100%;
    text-align: justify;
    .container {
      width: rem(1150px);
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    &__content {
      padding-top: 0;
      background: none;
    }
    &__row,
    &__docs {
      flex-direction: row;
      justify-content: space-between;
    }
    &__docs {
      justify-content: space-around;
      padding-bottom: rem(35px);
      margin-bottom: rem(30px);
      border-bottom: rem(2px) solid $red;
      text-transform: uppercase;
      font-size: rem(16px);
      &-item {
        margin-bottom: 0;
        line-height: normal;
        cursor: pointer;
      }
    }
    &__copyright {
      margin-top: 0;
      text-align: left;
      font-size: rem(16px);
    }
    &__text {
      font-size: rem(13px);
      line-height: 1.4;
    }
    &__col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &:last-of-type {
        flex: 0 0 58%;
      }
    }
  }
}
</style>
