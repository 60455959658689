<template>
  <section class="steps section" id="steps">
    <div class="container">
      <div class="steps__content">
        <h2 class="section-title">условия участия</h2>
        <div class="steps__list">
          <div class="steps__item" v-for="(item, idx) in steps" :key="idx">
            <div class="steps__item-img-wrapper">
              <img
                :src="require('../assets/images/steps/' + item.img)"
                alt=""
                class="steps__item-img"
              />
            </div>
            <div class="steps__item-content">
              <div class="steps__item-title" v-html="item.title"></div>
              <div class="steps__item-descr" v-html="item.text"></div>
              <img
                v-if="item.monetka === true"
                src="../assets/images/monetka-2.svg"
                alt=""
                class="steps__item-monetka visible-xs"
              />
              <button
                v-if="item.button === true"
                @click="toCheckUpload()"
                class="btn btn--primary"
              >
                <span>зарегистрировать чек</span>
                <div class="icon-wrapper">
                  <svg
                    class="icon"
                    width="30"
                    height="16"
                    viewBox="0 0 30 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.93677 7C1.38448 7 0.936768 7.44772 0.936768 8C0.936768 8.55228 1.38448 9 1.93677 9L1.93677 7ZM28.8006 8.70711C29.1911 8.31658 29.1911 7.68342 28.8006 7.29289L22.4366 0.928932C22.0461 0.538408 21.4129 0.538408 21.0224 0.928932C20.6319 1.31946 20.6319 1.95262 21.0224 2.34315L26.6792 8L21.0224 13.6569C20.6319 14.0474 20.6319 14.6805 21.0224 15.0711C21.4129 15.4616 22.0461 15.4616 22.4366 15.0711L28.8006 8.70711ZM1.93677 9L28.0935 9V7L1.93677 7L1.93677 9Z"
                      fill="#F8E9D9"
                    />
                  </svg>
                  <svg
                    class="icon"
                    width="30"
                    height="16"
                    viewBox="0 0 30 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.93677 7C1.38448 7 0.936768 7.44772 0.936768 8C0.936768 8.55228 1.38448 9 1.93677 9L1.93677 7ZM28.8006 8.70711C29.1911 8.31658 29.1911 7.68342 28.8006 7.29289L22.4366 0.928932C22.0461 0.538408 21.4129 0.538408 21.0224 0.928932C20.6319 1.31946 20.6319 1.95262 21.0224 2.34315L26.6792 8L21.0224 13.6569C20.6319 14.0474 20.6319 14.6805 21.0224 15.0711C21.4129 15.4616 22.0461 15.4616 22.4366 15.0711L28.8006 8.70711ZM1.93677 9L28.0935 9V7L1.93677 7L1.93677 9Z"
                      fill="#98090C"
                    />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="steps__umbrella _left">
      <img src="../assets/images/animations/umbrella.svg" alt="" />
    </div>
    <div class="steps__umbrella _right hidden-xs">
      <img src="../assets/images/animations/umbrella.svg" alt="" />
    </div>
  </section>
</template>
<script>
export default {
  components: {},
  data: function () {
    return {
      publicPath: process.env.BASE_URL,
      steps: [
        {
          img: "step_1.svg",
          title: "КУПИТЕ",
          text: "любой чай <br class='visible-xs'>&laquo;Принцесса Нури&raquo; <br>в&nbsp;сети <span class='hidden-xs'>&laquo;Монетка&raquo;</span>",
          monetka: true,
        },
        {
          img: "step_2.svg",
          title: "регистрируйте",
          text: "чеки на сайте",
          button: true,
        },
        {
          img: "step_3.svg",
          title: "выигрывайте",
          text: "призы",
        },
      ],
    };
  },
  methods: {
    showModal(name) {
      this.$modal.show(name);
    },

    toCheckUpload() {
      // const t = this;
      // this.gtmEvent("register_receipt_2_screen", "click", "internal");
      // if (this.$store.getters.user) {
      //   let apmButton = document.querySelector(
      //     "#apm-scan-qr .apm-action-button"
      //   );
      //   if (apmButton) apmButton.click();
      //   setTimeout(() => {
      //     const closeBtn = document.getElementById("apm-close-widget-button");

      //     console.log(closeBtn);
      //     closeBtn.addEventListener("click", function () {
      //       t.gtmEvent("left_receipt_registration", "do", "internal");
      //     });
      //   }, 1000);
      // } else {
      //   this.$modal.show("authorization");
      // }
      this.$modal.show("temporarily_unavailable");
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.steps {
  position: relative;
  display: flex;
  padding-top: rem(50px);
  padding-bottom: rem(200px);
  .container {
    position: relative;
    z-index: 99;
  }
  &__content {
    position: relative;
    display: flex;
    width: 100%;
    padding-top: rem(50px);
    flex-direction: column;
    z-index: 9;
  }
  .section-title {
    margin-bottom: rem(20px);
    font-size: rem(40px);
  }
  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }
  &__item {
    display: flex;
    max-width: 100vw;
    width: 100%;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: rem(35px);
    &-content {
      width: 100%;
      text-align: center;
    }
    &-img {
      max-height: 100%;
      object-fit: contain;
      &-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        margin-bottom: rem(13px);
        height: rem(80px);
      }
    }
    &-title {
      font-size: rem(28px);
      line-height: rem(40px);
      letter-spacing: rem(1px);
      font-family: $bold;
      text-transform: uppercase;
    }
    &-descr {
      margin: 0 auto;
      font-size: rem(20px);
      line-height: rem(26px);
      font-weight: 400;
      a,
      span {
        text-decoration: none;
      }
      img {
        display: inline;
      }
    }
    &-monetka {
      height: rem(49px);
      margin: rem(-15px) auto 0;
    }
    .btn {
      margin-top: rem(10px);
    }
  }
  &__umbrella {
    position: absolute;
    width: rem(152px);
    &._right {
      left: 30%;
      bottom: -25%;
      img,
      &::before {
        animation-delay: 1s;
      }
    }
    &._left {
      left: -9%;
      bottom: -12%;
    }
    img {
      animation: umbrella 30s linear infinite;
    }
    &::before {
      content: "";
      position: absolute;
      top: 15%;
      left: 15%;
      display: block;
      width: 100%;
      height: 100%;
      background: url("../assets/images/animations/shadow.svg") no-repeat center;
      animation: umbrella 30s linear infinite;
      background-size: contain;
    }
  }
  @media (min-width: $sm) {
    padding-top: rem(135px);
    padding-bottom: rem(130px);
    .section-title {
      font-size: rem(40px);
      margin-bottom: rem(55px);
    }
    &__content {
      padding-top: rem(50px);
    }
    &__umbrella {
      width: rem(182px);
      &._left {
        left: -2%;
        bottom: -10%;
      }
    }
    &__list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
    }

    &__item {
      flex: 0 0 33%;
      margin-bottom: 0;
      &-img {
        &-wrapper {
          max-width: 100%;
          height: rem(100px);
          margin-bottom: rem(14px);
        }
      }
      .btn {
        margin-top: rem(28px);
      }
      &-small {
        min-height: rem(18px);
        margin-bottom: rem(5px);
        margin-left: rem(45px);
        text-align: left;
        font-size: rem(15px);
        line-height: rem(18px);
      }
      &-title {
        margin-bottom: rem(3px);
        font-size: rem(32px);
        line-height: rem(40px);
      }
      &-descr {
        margin: auto;
        font-size: rem(24px);
        line-height: rem(31px);
        white-space: nowrap;
      }
    }
  }
  @media (min-width: $md) {
    .container {
      display: flex;
      align-items: center;
    }
    &__list {
      margin: auto;
      margin-top: 0;
      padding-bottom: 0;
    }
  }
}
@keyframes umbrella {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
