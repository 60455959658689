import Vue from "vue";
import App from "./App.vue";
import "jquery";

import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import "./assets/styles/main.scss";
import VModal from "vue-js-modal";

import typograf from "./utils/filters";
import VueScrollactive from "vue-scrollactive";
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import VTooltip from "v-tooltip";
import VueSmoothScroll from "vue2-smooth-scroll";
import Vuelidate from "vuelidate";
import VueTheMask from "vue-the-mask";
import LoadScript from "vue-plugin-load-script";
import DadataSuggestions from "vue-dadata-suggestions";
import VueMeta from "vue-meta";
import VueResource from "vue-resource";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import VueYandexMetrika from 'vue-yandex-metrika'

Vue.use(PerfectScrollbar);
Vue.use(VueResource);

Vue.use(VueMeta);
Vue.use(DadataSuggestions, {
  token: "e3468f8470bd9bff626cc9a80dfc9cceb5d01020",
  type: "ADDRESS",
});
Vue.use(VueYandexMetrika, {
  id: 56029951,
  router: router,
  env: "production",
  debug: true,
})
Vue.use(LoadScript);
Vue.use(VueScrollactive);
Vue.component("v-select", vSelect);
Vue.use(VTooltip);
Vue.filter("text", typograf);
Vue.use(VueSmoothScroll, {
  offset: -150,
  duration: 400,
  updateHistory: false,
});
Vue.use(VueTheMask);
Vue.use(VModal);
Vue.use(Vuelidate);

Vue.config.productionTip = false;
Vue.mixin({
  methods: {
    gtmEvent: (label, action, category) => {
      window.dataLayer?.push({
        event: "gaEvent",
        eventCategory: category || "",
        eventAction: action || "",
        eventLabel: label || "",
      });
    },
    rememberSoc(item) {
      localStorage.socialName = item;
    },
  },
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
