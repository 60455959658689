var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"signup"},[_c('div',{staticClass:"modal__subtitle"},[_vm._v("для участия в акции")]),_c('div',{staticClass:"signup__fields"},[_c('inputText',{class:{
        error:
          _vm.validationStatus(_vm.$v.first_name) || this.errorMessage.first_name,
      },attrs:{"label":"имя*","placeholder":"Ваше имя","field":_vm.first_name,"error":(!_vm.$v.first_name.required && _vm.$v.first_name.$error
          ? 'Обязательное поле'
          : !_vm.$v.first_name.rus && _vm.$v.first_name.$error
          ? 'Введите текст на русском языке'
          : '') || this.errorMessage.first_name},on:{"input":function($event){_vm.first_name = $event}}}),_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.last_name) || this.errorMessage.last_name,
      },attrs:{"label":"Фамилия*","placeholder":"Ваша фамилия","field":_vm.last_name,"error":(!_vm.$v.last_name.required && _vm.$v.last_name.$error
          ? 'Обязательное поле'
          : !_vm.$v.last_name.rus && _vm.$v.last_name.$error
          ? 'Введите текст на русском языке'
          : '') || this.errorMessage.last_name},on:{"input":function($event){_vm.last_name = $event}}}),_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.email) || this.errorMessage.email,
      },attrs:{"label":"E-mail","placeholder":"Ваш E-mail","field":_vm.email,"type":"email","error":(!_vm.$v.email.required && _vm.$v.email.$error
          ? 'Обязательное поле'
          : !_vm.$v.email.email && _vm.$v.email.$error
          ? 'Некорректный e-mail'
          : '') || this.errorMessage.email},on:{"input":function($event){_vm.email = $event}}}),_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.phone) || this.errorMessage.phone,
      },attrs:{"label":"Номер телефона","placeholder":"+7 (_ _ _) - _ _ _ -_ _ - _ _","field":_vm.phone,"type":"tel","mask":'+7(###) ###-##-##',"error":(!_vm.$v.phone.required && _vm.$v.phone.$error
          ? 'Обязательное поле'
          : !_vm.$v.phone.minLength && _vm.$v.phone.$error
          ? 'Минимум 11 символов'
          : '') || this.errorMessage.phone},on:{"input":function($event){_vm.phone = $event}}}),_c('Select',{class:{
        error: _vm.validationStatus(_vm.$v.source) || _vm.errorMessage.source,
      },attrs:{"color":"grey","options":[
        { id: 1, label: 'Реклама на радио' },
        { id: 2, label: 'Реклама в интернете' },
        { id: 3, label: 'Реклама в магазине' },
        { id: 5, label: 'От друзей/знакомых' },
        { id: 4, label: 'Другое' },
      ],"placeholder":"Не выбрано","labelText":"откуда узнали об акции","error":_vm.errorMessage.source},on:{"input":function($event){_vm.source = $event.label}}})],1),_c('div',{staticClass:"signup__checkbox_list"},[_c('checkBox',{class:{
        error: _vm.validationStatus(_vm.$v.checkbox) || this.errorMessage.checkbox,
      },attrs:{"color":"white","label":`Я согласен с <a href='${_vm.publicPath}docs/rules.pdf' class='_undeline-link _bold' target='_blank'>правилами акции</a>`,"field":_vm.checkbox,"error":this.errorMessage.checkbox},on:{"input":function($event){_vm.checkbox = $event}}}),_c('checkBox',{class:{
        error: _vm.validationStatus(_vm.$v.checkbox2) || this.errorMessage.checkbox2,
      },attrs:{"color":"white","label":`Я&nbsp;даю согласие на&nbsp;обработку <a href='${_vm.publicPath}docs/terms-of-use.pdf' class='_undeline-link _bold' target='_blank'>персональных данных</a>`,"field":_vm.checkbox2,"error":this.errorMessage.checkbox2},on:{"input":function($event){_vm.checkbox2 = $event}}})],1),_c('div',{staticClass:"signup__footer"},[_c('button',{staticClass:"btn btn--secondary w-100",class:{ loading: _vm.submitStatus == 'PENDING' },attrs:{"type":"button"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Зарегистрироваться"),_c('img',{staticClass:"loader",attrs:{"src":require("../../assets/img/spinner.svg"),"alt":""}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }