<template>
  <div class="form-select">
    <div class="form__caption" v-if="labelText">{{ labelText }}</div>
    <v-select
      :searchable="false"
      :options="options"
      v-model.trim="retValue"
      :name="name"
      :placeholder="placeholder"
      @focus="errorReset()"
      @change="onInput()"
      @input="onInput()"
      :class="className"
    >
      <span slot="no-options">Ничего не найдено</span>
    </v-select>

    <div class="error-hint" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      retValue: this.field,
    };
  },

  methods: {
    errorReset() {
      this.$parent.errorReset();
    },

    onInput() {
      this.$emit("input", this.retValue);
    },
  },
  props: [
    "options",
    "field",
    "name",
    "error",
    "labelText",
    "className",
    "reduceVale",
    "placeholder",
  ],
};
</script>

<style lang="scss">
$height: rem(32px);
$height-sm: rem(32px);
$color: #9c9b9b;
$font_size: rem(14px);
$background: #ffffff;
$border: #ffffff;
$align: left;
$radius: rem(20px);

.form-select {
  position: relative;
  max-width: 100%;
  margin: 0 auto rem(8px);
  z-index: 10;
  .v-select {
    height: $height-sm;
    z-index: 1;
    &._bordered {
      height: rem(56px);
      margin-bottom: rem(12px);
      .vs__open-indicator {
        width: rem(20px);
        height: rem(20px);
        background-image: url("../../assets/images/icons/select-arrow-2.svg");
        background-repeat: no-repeat;
        background-size: contain;
      }
      .vs__selected {
        position: relative;
        color: $red;
        font-family: $reg;
        text-transform: uppercase;
        text-align: center;
        padding: 2px 5px 0 0;
      }
      .vs__dropdown-toggle {
        padding: rem(3px) rem(25px) 0 rem(52px);
        border: 1px solid $red;
        border-radius: rem(30px);
        background: #fff2e5;
      }
      .vs__dropdown-menu {
        width: 100%;
        min-width: auto;
        margin-top: calc(-#{rem(56px)} + #{rem(1px)});
        padding-top: rem(60px);
        border-radius: rem(30px);
        background: #fff2e5;
        border: 1px solid $red;
      }
      .vs__dropdown-option {
        padding: rem(8px) rem(17px) rem(8px);
        color: $red;
        font-family: $reg;
      }
    }
  }
  &._rounded {
    .v-select {
      .vs__dropdown-toggle,
      .vs__dropdown-menu {
        border-radius: rem(30px);
      }
    }
  }
  &.error {
    .v-select {
      .vs__dropdown-toggle {
        border-color: #ec2a86 !important;
      }
    }
  }
  .vs__dropdown-toggle {
    position: relative;
    display: flex;
    align-items: center;
    line-height: 1;
    height: 100%;
    max-width: 100%;
    z-index: 1001;
    background: $background;
    border-radius: $radius;
    border: rem(2px) solid $background;
    padding: 2px rem(15px) 0 rem(17px);
  }

  .vs__selected-options {
    padding: 0;
    max-width: 90%;
  }

  .vs__search {
    padding: 0;
    margin: 0;
    border: 0;
    height: 100%;
    font-family: $bold;
    font-size: $font_size !important;
    color: $color;

    &:focus {
      border: 0;
      padding: 0;
      margin: 0;
    }
  }

  .vs__actions {
    margin-left: auto;
    padding: 0px 0px 0 3px;
  }

  .vs__clear {
    display: none;
  }

  .vs__open-indicator {
    width: rem(18px);
    height: rem(18px);
    background: url("../../assets/images/icons/select-arrow.svg") no-repeat
      center;
    background-size: contain;
    path {
      display: none;
    }
  }

  .vs__selected {
    width: 100%;
    display: block;
    bottom: 0;
    padding: 0;
    margin: 0;
    color: $red;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: $align;
    font-family: $bold;
    font-size: $font_size;
  }

  .vs--single.vs--open .vs__selected {
    position: relative;
    opacity: 1;
  }

  .vs__dropdown-menu {
    border: 0;
    margin-top: calc(-#{$height} + #{rem(1px)});
    padding-top: $height-sm;
    background: $background;
    border: rem(2px) solid $background;
    box-shadow: 0px 4px 4px rgba(99, 89, 66, 0.25);
    border-radius: $radius;
  }

  .vs__dropdown-option {
    max-width: 100%;
    white-space: normal;
    color: $color;
    font-size: $font_size;
    text-align: $align;
    font-family: $bold;
    padding: rem(10px) rem(17px) rem(10px);
  }

  .vs__dropdown-option--highlight {
    color: #000;
    background: $pink;
  }
  @media (min-width: $md) {
    margin: 0 auto rem(14px);
    .v-select {
      height: $height;
    }
    .vs__dropdown-toggle {
      border: rem(2px) solid $border;
    }
    .vs__dropdown-menu {
      padding-top: $height;
      border: rem(2px) solid $border;
    }
  }
}
</style>
