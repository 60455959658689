<template>
  <div class="prizes section" id="prizes">
    <div class="container fxdc">
      <div class="section-title _white visible-xs">ПРИЗЫ</div>
      <div class="prizes__content fxdc">
        <div class="prizes__list">
          <div class="prizes-item">
            <div class="prizes-item__content">
              <div class="prizes-item__red-block">
                подарочный сертификат на&nbsp;шопинг
              </div>
              <div class="prizes-item__big-text">3 000</div>
              <div class="prizes-item__text">рублей</div>
              <div class="btn btn--primary">ежедневно</div>
            </div>
          </div>
          <div class="prizes-item _big">
            <div class="prizes-item__content">
              <div class="prizes-item-img-wrapper">
                <img
                  src="../assets/images/prizes/main-prize.svg"
                  class="prizes-item-img"
                />
              </div>
              <div class="prizes-item__title">Поездка <br />на море</div>
              <div class="prizes-item__text">для всей семьи <br />на сумму</div>
              <div class="prizes-item__big-text">
                <span>300 000</span> рублей
              </div>
            </div>
          </div>
          <div class="prizes-item">
            <div class="prizes-item__content">
              <div class="prizes-item-img-wrapper">
                <img
                  src="../assets/images/prizes/prize-2.svg"
                  class="prizes-item-img hidden-xs"
                />
                <img
                  src="../assets/images/prizes/prize-2_2.svg"
                  class="prizes-item-img visible-xs"
                />
              </div>
              <div class="prizes-item__text _big">Большой<br />чемодан</div>
              <div class="btn btn--primary">еженедельно</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img
      class="prizes__ship hidden-xs"
      src="../assets/images/animations/ship-big.svg"
      alt=""
    />
    <div class="prizes__fish">
      <img src="../assets/images/animations/fish.svg" alt="" />
    </div>
    <div class="prizes__fish _right hidden-xs">
      <img src="../assets/images/animations/fish-2.svg" alt="" />
    </div>
    <AppBubbles left="6%" top="88%" :amount="10" class="hidden-xs" />
  </div>
</template>

<script>
import AppBubbles from "./AppBubbles.vue";
export default {
  components: { AppBubbles },
  props: {},
  data: function () {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.prizes {
  position: relative;
  align-items: center;
  padding-top: rem(143px);
  padding-bottom: rem(15px);
  flex-direction: column;
  z-index: 2;
  &__content {
  }
  .container {
    position: relative;
    z-index: 1;
  }
  &__list {
    display: flex;
    flex-direction: column;
  }
  .section-title {
    font-size: rem(42px);
    margin-bottom: rem(100px);
  }
  &-item {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0 rem(10px) rem(42px);
    margin-bottom: rem(68px);
    background: linear-gradient(360deg, #f49d82 0%, #f7d6ab 100%);
    border-radius: rem(30px);
    &:last-of-type {
      margin-bottom: 0;
    }
    &._big {
      order: -1;
      height: auto;
      padding-top: rem(50px);
      padding-bottom: rem(16px);
    }
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__title {
      margin-bottom: rem(31px);
      font-size: rem(24px);
    }
    &-img {
      height: 100%;
      &._alsfe {
        align-self: flex-end;
      }
      &-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: rem(226px);
        margin: rem(-30px) auto rem(13px);
        ._big & {
          position: absolute;
          top: rem(-25px);
          left: 50%;
          margin: 0;
          transform: translate(-50%, -50%);
          height: rem(112px);
        }
      }
    }
    &__red-block {
      width: rem(243px);
      margin: rem(-30px) auto rem(40px);
      padding: rem(30px) rem(30px) rem(30px);
      background: #b0000d url("../assets/images/red-corner.svg") no-repeat
        bottom right;
      background-size: 100%;
      border-radius: rem(12px);
      color: $light;
      font-size: rem(18px);
      line-height: 1.5;
      font-family: $bold;
      text-shadow: 0px rem(2px) rem(4px) rgba(0, 0, 0, 0.25);
      text-transform: uppercase;
    }
    &__big-text {
      margin-bottom: rem(15px);
      font-size: rem(48px);
      color: #fff;
      font-family: $medium-font;
      line-height: rem(23px);
      ._big & {
        margin-top: rem(15px);
        margin-bottom: 0;
        font-size: rem(24px);
        font-family: $medium-font;
        line-height: 1;
        color: $red;
        text-shadow: none;
      }
      span {
        font-size: rem(48px);
        line-height: 1;
      }
    }
    &__text {
      margin-bottom: rem(6px);
      font-size: rem(24px);
      line-height: 1;
      font-family: $medium-font;
      color: #fff9f2;
      text-align: center;
      &._big {
        font-size: rem(30px);
        text-transform: uppercase;
      }
    }
    &__title {
      margin-bottom: rem(12px);
      font-size: rem(40px);
      line-height: 1;
      color: #fff9f2;
      text-transform: uppercase;
      font-family: $bold;
      text-shadow: 0px rem(2px) rem(4px) rgba(0, 0, 0, 0.25);
    }
    .btn {
      position: absolute;
      top: 99%;
      left: 50%;
      min-width: auto;
      width: rem(209px);
      height: rem(50px);
      line-height: rem(42px);
      font-size: rem(20px);
      text-transform: none;
      transform: translate(-50%, -50%);
      pointer-events: none;
      cursor: default;
    }
  }
  &__ship {
    position: absolute;
    right: rem(-150px);
    bottom: -30%;
    width: rem(380px);
    animation: ship 5s ease-in-out infinite;
  }
  &__fish {
    position: absolute;
    left: -25%;
    bottom: 10%;
    width: rem(200px);
    animation: fish 10s linear infinite;
    &._right {
      left: 100%;
      bottom: -90%;
      animation: fishReverse 10s 5s linear infinite;
      // animation: none;
    }
    img {
      animation: fishRotate 1s ease-in-out infinite;
    }
  }
  @media (min-width: $sm) {
    padding-top: rem(295px);
    padding-bottom: rem(0px);
    overflow: visible;
    z-index: 1;
    .container {
      display: flex;
      padding-top: rem(0px);
    }
    .section-title {
      margin-bottom: rem(55px);
    }
    &__fish {
      left: 25%;
      bottom: -70%;
      width: rem(270px);
    }
    &__content {
      flex-direction: column;
      align-items: center;
      padding-bottom: 0;
      padding-top: 0;
    }
    &__text {
      font-size: rem(18px);
      line-height: rem(25px);
      text-align: center;
      text-shadow: 0px rem(2px) rem(4px) rgba(0, 0, 0, 0.25);
    }
    &__list {
      width: 95%;
      margin: auto;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    &-item {
      flex-direction: row;
      flex: 0 0 27%;
      height: rem(535px);
      margin: 0 rem(20px) 0;
      padding: rem(22px) rem(10px) 0;
      max-height: 100%;
      &._big {
        flex: 0 0 36%;
        height: auto;
        padding-top: rem(156px);
        padding-bottom: rem(38px);
        order: 0;
      }
      &__content {
        width: 100%;
        height: 100%;
        display: block;
      }
      &__title {
        margin-bottom: rem(20px);
        font-size: rem(75px);
        line-height: 1;
        color: #fff9f2;
        text-transform: uppercase;
        font-family: $bold;
        text-shadow: 0px rem(2px) rem(4px) rgba(0, 0, 0, 0.25);
      }
      &-img {
        width: auto;
        height: 100%;
        &-wrapper {
          width: 100%;
          height: rem(363px);
          margin: rem(-60px) auto rem(45px);
          ._big & {
            position: absolute;
            top: 4%;
            left: 50%;
            margin: 0;
            transform: translate(-50%, -50%);
            height: rem(175px);
          }
        }
      }
      &__red-block {
        width: rem(263px);
        margin: rem(90px) auto rem(95px);
        padding: rem(35px) rem(30px) rem(25px);
        background: #b0000d;
        border-radius: rem(12px);
        color: $light;
        font-size: rem(26px);
        line-height: 1.2;
        font-family: $bold;
        text-shadow: 0px rem(2px) rem(4px) rgba(0, 0, 0, 0.25);
        text-transform: uppercase;
      }
      &__big-text {
        margin-bottom: rem(30px);
        font-size: rem(76px);
        color: $light;
        text-shadow: 0px rem(2px) rem(4px) rgba(0, 0, 0, 0.25);
        line-height: rem(22px);
        ._big & {
          margin-top: rem(20px);
          margin-bottom: 0;
          font-size: rem(32px);
          line-height: 1;
          font-weight: 400;
          color: $red;
          text-shadow: none;
        }
        span {
          font-size: rem(93px);
          line-height: 1;
        }
      }
      &__text {
        margin-bottom: rem(6px);
        font-size: rem(32px);
        line-height: 1;
        color: #fff9f2;
        text-align: center;
        text-shadow: 0px rem(2px) rem(4px) rgba(0, 0, 0, 0.25);
        &._big {
          font-size: rem(40px);
          text-transform: none;
        }
      }
      .btn {
        position: absolute;
        top: 99%;
        left: 50%;
        width: rem(283px);
        height: rem(59px);
        line-height: rem(55px);
        font-size: rem(26px);
        text-transform: none;
        transform: translate(-50%, -50%);
        pointer-events: none;
        cursor: default;
      }
    }
  }
}
@keyframes ship {
  0%,
  100% {
    transform: translate(-2%, -3%);
  }
  50% {
    transform: translate(1%, 3%);
  }
}
@keyframes fish {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(rem(350px), rem(-400px));
  }
  50%,
  100% {
    transform: translate(rem(900px), rem(-800px));
  }
}
@keyframes fishReverse {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(rem(-600px), rem(-450px));
  }
  50%,
  100% {
    transform: translate(rem(-1200px), rem(-900px));
  }
}
@keyframes fishRotate {
  0%,
  100% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(-3deg);
  }
}
</style>
