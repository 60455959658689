<template>
  <Modal
    name="info"
    title="как Получить приз"
    size="sm"
    @closeModal="closeModal"
  >
    <template v-slot:content>
      <div class="modal__text">
        <p>
          В&nbsp;течение&nbsp;2 (двух) рабочих дней с&nbsp;момента получения
          звонка от&nbsp;оператора и/или получения письма на&nbsp;электронную
          почту, указанную при регистрации, выслать на&nbsp;адрес электронной
          почты Организатора <br /><a
            href="mailto:info@noori-tea-promo.ru"
            class="_undeline-link"
            >info@noori-tea-promo.ru</a
          >
          следующие достоверные документы/сведения о&nbsp;себе:
        </p>
        <p>
          <br />&bull; Фамилия, имя, отчество; <br />&bull; Контактный номер
          телефона; <br />&bull; Почтовый адрес с&nbsp;индексом для доставки
          Приза; <br />&bull; Фото/скан страниц своего паспорта
          гражданина&nbsp;РФ (разворот с&nbsp;фотографией и&nbsp;страница
          с&nbsp;информацией о&nbsp;последнем месте регистрации); <br />&bull;
          Фото/скан свидетельства ИНН; <br />&bull; Скан подписанного согласия
          на&nbsp;обработку персональных данных в&nbsp;соответствии
          с&nbsp;Приложением 1&nbsp;к правилам акции. Участник обязан хранить
          оригинал указанного согласия на&nbsp;обработку персональных данных
          в&nbsp;течение&nbsp;1 (одного) года; <br />&bull; Иную необходимую
          информацию по&nbsp;запросу Организатора.
        </p>
        <p>
          <br />Участник обязан хранить оригинал указанного согласия
          на&nbsp;обработку персональных данных в&nbsp;течение&nbsp;1 (одного)
          года.
        </p>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data: function () {
    return {};
  },
  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },
    beforeOpen() {},
  },
};
</script>
