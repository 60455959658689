<template>
  <div class="signup">
    <div class="modal__subtitle">для участия в акции</div>
    <div class="signup__fields">
      <inputText
        label="имя*"
        placeholder="Ваше имя"
        :field="first_name"
        :class="{
          error:
            validationStatus($v.first_name) || this.errorMessage.first_name,
        }"
        :error="
          (!$v.first_name.required && $v.first_name.$error
            ? 'Обязательное поле'
            : !$v.first_name.rus && $v.first_name.$error
            ? 'Введите текст на русском языке'
            : '') || this.errorMessage.first_name
        "
        @input="first_name = $event"
      />
      <inputText
        label="Фамилия*"
        placeholder="Ваша фамилия"
        :field="last_name"
        :class="{
          error: validationStatus($v.last_name) || this.errorMessage.last_name,
        }"
        :error="
          (!$v.last_name.required && $v.last_name.$error
            ? 'Обязательное поле'
            : !$v.last_name.rus && $v.last_name.$error
            ? 'Введите текст на русском языке'
            : '') || this.errorMessage.last_name
        "
        @input="last_name = $event"
      />

      <inputText
        label="E-mail"
        placeholder="Ваш E-mail"
        :field="email"
        type="email"
        :class="{
          error: validationStatus($v.email) || this.errorMessage.email,
        }"
        :error="
          (!$v.email.required && $v.email.$error
            ? 'Обязательное поле'
            : !$v.email.email && $v.email.$error
            ? 'Некорректный e-mail'
            : '') || this.errorMessage.email
        "
        @input="email = $event"
      />
      <inputText
        label="Номер телефона"
        placeholder="+7 (_ _ _) - _ _ _ -_ _ - _ _"
        :field="phone"
        @input="phone = $event"
        type="tel"
        :mask="'+7(###) ###-##-##'"
        :class="{
          error: validationStatus($v.phone) || this.errorMessage.phone,
        }"
        :error="
          (!$v.phone.required && $v.phone.$error
            ? 'Обязательное поле'
            : !$v.phone.minLength && $v.phone.$error
            ? 'Минимум 11 символов'
            : '') || this.errorMessage.phone
        "
      />
      <Select
        color="grey"
        :options="[
          { id: 1, label: 'Реклама на радио' },
          { id: 2, label: 'Реклама в интернете' },
          { id: 3, label: 'Реклама в магазине' },
          { id: 5, label: 'От друзей/знакомых' },
          { id: 4, label: 'Другое' },
        ]"
        placeholder="Не выбрано"
        labelText="откуда узнали об акции"
        @input="source = $event.label"
        :class="{
          error: validationStatus($v.source) || errorMessage.source,
        }"
        :error="errorMessage.source"
      />
      <!-- <inputText
        placeholder="Пароль"
        :field="password"
        size="lg"
        maxlength="24"
        type="password"
        @input="password = $event"
        :class="{
          error: validationStatus($v.password) || errorMessage.password,
        }"
        :error="
          (!$v.password.required && $v.password.$error
            ? 'Обязательное поле'
            : !$v.password.minLength && $v.password.$error
            ? 'Минимум 6 символов'
            : !$v.password.maxLength && $v.password.$error
            ? 'Максимум 24 символа'
            : '') || errorMessage.password
        "
      />
      <inputText
        placeholder="повторите Пароль"
        :field="repPassword"
        size="lg"
        type="password"
        maxlength="24"
        @input="repPassword = $event"
        :class="{
          error: validationStatus($v.repPassword) || errorMessage.repPassword,
        }"
        :error="
          (!$v.repPassword.required && $v.repPassword.$error
            ? 'Обязательное поле'
            : !$v.repPassword.minLength && $v.repPassword.$error
            ? 'Минимум 6 символов'
            : !$v.repPassword.maxLength && $v.repPassword.$error
            ? 'Максимум 24 символа'
            : !$v.repPassword.sameAsPassword && $v.repPassword.$error
            ? 'Пароли не совпадают'
            : '') || errorMessage.repPassword
        "
      /> -->
      <!-- <label
        class="form__field form__field--white"
        :class="{
          error: validationStatus($v.city) || this.errorMessage.city,
        }"
      >
        <dadata-suggestions
          id="city"
          placeholder="Город"
          class="form__field-input form__field-input--lg"
          field-value="value"
          :options="dadataOptionsCity"
          v-model="city"
        />
        <div class="error-hint" v-if="errorMessage.city || $v.city.$error">
          {{
            (!$v.city.required && $v.city.$error ? "Обязательное поле" : "") ||
            errorMessage.city
          }}
        </div>
      </label> -->
    </div>

    <div class="signup__checkbox_list">
      <checkBox
        color="white"
        :label="`Я согласен с <a href='${publicPath}docs/rules.pdf' class='_undeline-link _bold' target='_blank'>правилами акции</a>`"
        :field="checkbox"
        :class="{
          error: validationStatus($v.checkbox) || this.errorMessage.checkbox,
        }"
        :error="this.errorMessage.checkbox"
        @input="checkbox = $event"
      />
      <checkBox
        color="white"
        :label="`Я&nbsp;даю согласие на&nbsp;обработку <a href='${publicPath}docs/terms-of-use.pdf' class='_undeline-link _bold' target='_blank'>персональных данных</a>`"
        :field="checkbox2"
        :class="{
          error: validationStatus($v.checkbox2) || this.errorMessage.checkbox2,
        }"
        :error="this.errorMessage.checkbox2"
        @input="checkbox2 = $event"
      />
      <!-- <checkBox
        color="white"
        label="Я даю согласие на&nbsp;получение дополнительной информационной рассылки"
        :field="checkbox3"
        :class="{
          error: validationStatus($v.checkbox3) || this.errorMessage.checkbox3,
        }"
        :error="this.errorMessage.checkbox3"
        @input="checkbox3 = $event"
      /> -->
    </div>
    <div class="signup__footer">
      <button
        type="button"
        class="btn btn--secondary w-100"
        @click="submit()"
        :class="{ loading: submitStatus == 'PENDING' }"
      >
        Зарегистрироваться<img
          src="../../assets/img/spinner.svg"
          class="loader"
          alt=""
        />
      </button>
      <!-- <div class="modal__another-option">
        <p>Уже есть аккаунт? <a href="#" @click="showLogInModal()">Войти</a></p>
      </div> -->
    </div>
  </div>
</template>

<script>
import inputText from "@/components/form/inputText.vue";
import checkBox from "@/components/form/checkBox.vue";
import Select from "@/components/form/inputSelect.vue";

import {
  required,
  email,
  minLength,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";
const rus = (value) => /^[А-ЯЁа-яё ]+$/.test(value);

export default {
  name: "SignupForm",
  props: ["network"],
  data() {
    return {
      publicPath: "https://www.noori-tea-promo.ru/",
      ready: false,
      email: null,
      first_name: null,
      last_name: null,
      phone: null,
      city: null,
      source: null,
      // password: null,
      // repPassword: null,
      checkbox: false,
      checkbox2: false,
      checkbox3: true,
      dadataOptionsCity: {
        bounds: "city-city",
        type: "ADDRESS",
        geoLocation: false,
      },
      errorMessage: {
        email: null,
        first_name: null,
        last_name: null,
        phone: null,
        city: null,
        source: null,
        // password: null,
        // repPassword: null,
        checkbox: null,
        checkbox2: null,
        checkbox3: null,
      },

      submitStatus: null,
    };
  },

  validations: {
    email: { required, email },
    first_name: { required, rus },
    last_name: { required, rus },
    phone: { required, minLength: minLength(11) },
    // city: { required },
    // password: {
    //   required,
    //   minLength: minLength(6),
    //   maxLength: maxLength(24),
    // },
    // repPassword: {
    //   required,
    //   minLength: minLength(6),
    //   maxLength: maxLength(24),
    //   sameAsPassword: sameAs('password'),
    // },
    checkbox: {
      required,
      sameAs: sameAs(() => true),
    },
    checkbox2: {
      required,
      sameAs: sameAs(() => true),
    },
  },
  watch: {
    checkbox(newVal) {
      if (!newVal) {
        this.$store.commit("SET_AGREE", false);
      }
    },
  },
  methods: {
    selectSuggestion(suggestion) {
      this.city = suggestion.data.city;
    },
    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        email: null,
        first_name: null,
        last_name: null,
        phone: null,
        city: null,
        source: null,
        // repPassword: null,
        // password: null,
        checkbox: null,
        checkbox2: null,
        checkbox3: null,
      };
    },
    showRules() {
      if (!this.$store.getters.agree) {
        this.$modal.show("document_popup", {
          src: this.publicPath + "docs/rules.pdf",
        });
      }
    },
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },

    submit() {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;

      if (this.submitStatus !== "PENDING") {
        this.submitStatus = "PENDING";
        const formData = {
          login: this.email,
          name: this.first_name,
          surname: this.last_name,
          phone: this.phone,
          source: this.source,
          // pass: this.password,
          // city: this.city,
          rules1: this.checkbox,
          rules2: this.checkbox2,
          rules3: this.checkbox3,
        };
        this.$store
          .dispatch("SignUp", formData)
          .then((r) => {
            console.log(r);
            if (!r.result) {
              this.submitStatus = null;

              let fieldsError = null;

              if (r.error && r.error.email) {
                [this.errorMessage.email] = r.error.email || "";
                fieldsError = true;
              } else if (r.error && r.error.login) {
                [this.errorMessage.email] = r.error.login;
                fieldsError = true;
              }
              if (r.error && r.error.phone) {
                [this.errorMessage.phone] = r.error.phone;
                fieldsError = true;
              }
              if (r.error && r.error.city) {
                [this.errorMessage.city] = r.error.city;
                fieldsError = true;
              }
              if (r.error && r.error.name) {
                [this.errorMessage.first_name] = r.error.name;
                fieldsError = true;
              }
              if (r.error && r.error.surname) {
                [this.errorMessage.last_name] = r.error.surname;
                fieldsError = true;
              }
              if (r.error && r.error.password) {
                [this.errorMessage.password] = r.error.password;
                fieldsError = true;
              }

              if (r.error && !fieldsError) {
                this.$modal.show("common_error", {
                  title: r.error,
                });
              }
            } else {
              this.$modal.hide("signup");
              this.gtmEvent("enter", "form", "send");
              this.$metrika.reachGoal('enter');
              document.getElementById('lk').click();
              document.getElementById('sign_in').click();
              this.gtmEvent("registration_success", "do", "internal");
              this.$modal.show("success_signup", { email: this.email });
              // this.$store.commit("SET_TOKEN", r.data[0]);

              // this.$store.dispatch("GetProfile").then(() => {
              //   this.$router.push({ name: "Profile"});
              // });
            }
          })
          .catch((e) => {
            this.submitStatus = null;
            console.log(e);
          });
      }
    },
    showLogInModal() {
      this.$modal.hide("signup");
      this.$modal.show("authorization");
    },
  },
  mounted() {
    console.log(this.$route);
  },
  components: {
    inputText,
    checkBox,
    Select,
  },
};
</script>
<style lang="scss">
.suggestions-hint {
  display: none;
}
.suggestions-wrapper {
  position: absolute !important;
  margin-top: rem(10px) !important;
  width: 100%;
  z-index: 100;
}
.suggestions-suggestions {
  border: none !important;
  border-radius: rem(0px);
  overflow: auto;
  border: 1px solid $yellow;
}
.suggestions-suggestion {
  padding: rem(10px) rem(20px) !important;
  &:hover {
    background: rgba($green-light, 0.5) !important;
  }
}
.suggestions-value {
  font-size: rem(16px);
  strong {
    font-weight: bold !important;
    color: $red !important;
  }
}
</style>
<style lang="scss" scoped>
.signup {
  &__row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__checkbox_list {
    margin-top: rem(30px);
    margin-bottom: rem(30px);
  }
  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media (min-width: $sm) {
    &__row {
      flex-direction: row;
    }
    &__checkbox_list {
    }
  }
}
</style>
