<template>
  <div class="feedback">
    <form class="feedback__form" :key="indexForm">
      <inputText
        placeholder="E-mail"
        :field="email"
        @input="email = $event"
        class="_centered-text _bordered"
        :class="{
          error: validationStatus($v.email) || errorMessage.email,
        }"
        :error="
          (!$v.email.required && $v.email.$error
            ? 'Обязательное поле'
            : !$v.email.email && $v.email.$error
            ? 'Некорректный e-mail'
            : '') || errorMessage.email
        "
      />
      <inputText
        class="_centered-text _bordered"
        placeholder="Имя"
        :field="name"
        @input="name = $event"
        :class="{ error: validationStatus($v.name) || errorMessage.name }"
        :error="
          (!$v.name.required && $v.name.$error
            ? 'Обязательное поле'
            : !$v.name.rus && $v.name.$error
            ? 'Введите текст на русском языке'
            : '') || errorMessage.name
        "
      />
      <Select
        color="grey"
        className="_bordered"
        :options="[
          { id: 1, label: 'Регистрация в акции' },
          { id: 2, label: 'Призовой фонд' },
          { id: 3, label: 'Вопросы по правилам' },
          { id: 4, label: 'Технические вопросы' },
          { id: 5, label: 'Другое' },
        ]"
        field="Тема"
        @input="subject = $event.id"
        :class="{
          error: validationStatus($v.subject) || errorMessage.subject,
        }"
        :error="
          (!$v.subject.required && $v.subject.$error
            ? 'Обязательное поле'
            : '') || errorMessage.subject
        "
      />
      <textArea
        placeholder="Текст обращения"
        :field="message"
        :key="indexForm2"
        @input="message = $event"
        :class="{
          error: validationStatus($v.message) || errorMessage.message,
        }"
        :error="
          (!$v.message.required && $v.message.$error
            ? 'Обязательное поле'
            : '') || errorMessage.message
        "
      />
      <div class="feedback__checkbox_list">
        <checkBox
          :label="`Я даю согласие на&nbsp;обработку <br><a href='${publicPath}docs/terms-of-use.pdf' target='_blank' class='_undeline-link _bold'>персональных данных</a>`"
          :field="checkbox"
          :class="{
            error: validationStatus($v.checkbox) || this.errorMessage.checkbox,
          }"
          :error="this.errorMessage.checkbox"
          @input="checkbox = $event"
        />
      </div>
      <div class="feedback__footer">
        <button
          type="button"
          class="btn btn--bordered w-100"
          @click="submit(), gtmEvent('ask_question', 'click', 'internal')"
          :class="{ loading: submitStatus == 'PENDING' }"
        >
          <span>задать вопрос</span>
          <div class="icon-wrapper">
            <svg
              class="icon"
              width="30"
              height="16"
              viewBox="0 0 30 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.93677 7C1.38448 7 0.936768 7.44772 0.936768 8C0.936768 8.55228 1.38448 9 1.93677 9L1.93677 7ZM28.8006 8.70711C29.1911 8.31658 29.1911 7.68342 28.8006 7.29289L22.4366 0.928932C22.0461 0.538408 21.4129 0.538408 21.0224 0.928932C20.6319 1.31946 20.6319 1.95262 21.0224 2.34315L26.6792 8L21.0224 13.6569C20.6319 14.0474 20.6319 14.6805 21.0224 15.0711C21.4129 15.4616 22.0461 15.4616 22.4366 15.0711L28.8006 8.70711ZM1.93677 9L28.0935 9V7L1.93677 7L1.93677 9Z"
                fill="#98090C"
              />
            </svg>
            <svg
              class="icon"
              width="30"
              height="16"
              viewBox="0 0 30 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.93677 7C1.38448 7 0.936768 7.44772 0.936768 8C0.936768 8.55228 1.38448 9 1.93677 9L1.93677 7ZM28.8006 8.70711C29.1911 8.31658 29.1911 7.68342 28.8006 7.29289L22.4366 0.928932C22.0461 0.538408 21.4129 0.538408 21.0224 0.928932C20.6319 1.31946 20.6319 1.95262 21.0224 2.34315L26.6792 8L21.0224 13.6569C20.6319 14.0474 20.6319 14.6805 21.0224 15.0711C21.4129 15.4616 22.0461 15.4616 22.4366 15.0711L28.8006 8.70711ZM1.93677 9L28.0935 9V7L1.93677 7L1.93677 9Z"
                fill="#F8E9D9"
              />
            </svg>
          </div>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import inputText from "@/components/form/inputText.vue";
import checkBox from "@/components/form/checkBox.vue";
import textArea from "@/components/form/textArea.vue";
import Select from "@/components/form/inputSelect.vue";

import { required, email, sameAs } from "vuelidate/lib/validators";
const rus = (value) => /^[А-ЯЁа-яё ]+$/.test(value);

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default {
  name: "FeedbackForm",
  data() {
    return {
      publicPath: "https://www.noori-tea-promo.ru/",
      indexForm: 1,
      indexForm2: 1,
      ready: false,
      name: null,
      email: null,
      message: null,
      subject: null,
      checkbox: false,
      attach: "",
      errorMessage: {
        email: null,
        name: null,
        message: null,
        subject: null,
        checkBox: null,
      },

      submitStatus: null,

      chosenFileName: {},
    };
  },

  validations: {
    message: { required },
    email: { required, email },
    subject: { required },
    name: { required, rus },
    checkbox: {
      required,
      sameAs: sameAs(() => true),
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  watch: {
    message(newVal) {
      if (newVal === null) {
        this.indexForm2++;
      }
    },
  },
  methods: {
    submitMedia(e) {
      let t = this;

      this.chosenFileName = e;

      if (this.chosenFileName.length) {
        getBase64(this.chosenFileName[0]).then(function (data) {
          t.attach = data;
        });
      }
    },
    deleteFile() {
      this.chosenFileName = {};
      this.attach = "";
    },

    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        email: null,
        name: null,
        message: null,
        subject_id: null,
        checkBox: null,
      };
    },

    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },

    submit() {
      console.log("submit");
      this.$v.$touch();

      if (this.$v.$pendding || this.$v.$error) return;

      if (this.submitStatus !== "PENDING") {
        this.submitStatus = "PENDING";

        this.$store
          .dispatch("SendFeedback", {
            name: this.name,
            email: this.email,
            subject_id: this.subject,
            text: this.message,
            accept1: 1,
          })
          .then((r) => {
            console.log(r);
            if (!r.result) {
              this.submitStatus = null;

              this.captcha = null;
              let fieldsError;
              if (r.error && r.error.email) {
                [this.errorMessage.email] = r.error.email;
                fieldsError = true;
              } else if (r.error && r.error.login) {
                [this.errorMessage.email] = r.error.login;
                fieldsError = true;
              }

              if (r.error && r.error.name) {
                [this.errorMessage.name] = r.error.name;
                fieldsError = true;
              }

              if (r.error && r.error.text) {
                [this.errorMessage.message] = r.error.text;
                fieldsError = true;
              }
              if (r.error && r.error.subject_id) {
                [this.errorMessage.subject] = r.error.subject_id;
                fieldsError = true;
              }
              if (r.error && r.error.accept1) {
                [this.errorMessage.checkBox] = r.error.accept1;
                fieldsError = true;
              }
            } else {
              this.indexForm++;
              this.indexForm2++;
              setTimeout(() => {
                this.$v.$reset();
                this.message = null;
              }, 0);
              this.submitStatus = null;
              this.$modal.hide("feedback");
              this.$modal.show("common_success", {
                title: "Спасибо за&nbsp;Ваше обращение!",
                text: `Оно будет обработано в&nbsp;течение 72 часов. Мы&nbsp;пришлем ответ&nbsp;на ${this.email} ожидайте.`,
              });
            }
          })
          .catch(() => {
            this.submitStatus = null;
          });
      }
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.user) {
        this.indexForm++;
        this.name = this.user.profile.name;
        this.email = this.user.profile.email;
      }
    }, 1000);
  },
  components: {
    inputText,
    textArea,
    checkBox,
    Select,
  },
};
</script>

<style lang="scss">
.feedback {
  width: 100%;
  .form-checkbox__caption {
    margin-left: rem(18px);
  }
  &__text {
    margin-bottom: rem(8px);
    font-size: rem(12px);
    text-transform: none;
  }
  &__checkbox_list {
    margin-bottom: rem(20px);
  }
  @media (min-width: $sm) {
    &__text {
      font-size: rem(16px);
    }
  }
  @media (min-width: $md) {
    width: 82%;
    &__text {
      font-size: rem(15px);
    }
  }
}
</style>
