<template>
  <div class="bubbles hidden-xs" :style="{ left: left, top: top }">
    <span v-for="i in amount" :key="'amount' + top + i"></span>
  </div>
</template>

<script>
export default {
  props: {
    top: {
      require: true,
    },
    left: {
      require: true,
    },
    amount: {
      require: true,
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
.bubbles {
  position: absolute;
  width: rem(150px);
  height: rem(200px);
  span {
    display: block;
    position: absolute;
    bottom: 0;
    opacity: 0;
    @include size(rem(14px));
    background: #fff;
    border-radius: 50%;
    animation: bubble 3s linear infinite;
    &:nth-child(4n) {
      animation-duration: 2s;
      @include size(rem(20px));
    }
    &:nth-child(3n) {
      animation-duration: 4s;
      @include size(rem(10px));
    }
    &:nth-child(5n) {
      @include size(rem(6px));
    }
  }
  @for $i from 1 through 10 {
    $randomNumber: random(100);
    $left: #{$randomNumber} + "%";
    $bottom: -#{$randomNumber} + "%";
    $delay: #{$randomNumber / 10};
    $opacity: calc($randomNumber / 100);
    span:nth-of-type(#{$i}) {
      left: $left;
      animation-delay: $delay + "s";
      background: rgba(#fff, $opacity);
    }
  }
}
@keyframes bubble {
  0% {
    transform: translateY(0%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(rem(-150px));
    opacity: 0;
  }
}
</style>
